<template>
  <div class="view pa24">
    <div style="font-size: 26px;font-weight: bold;margin-bottom: 30px;">密码管理</div>
    <el-form style="width: 30%;" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
      <el-form-item label="旧密码" prop="oldPassword">
        <el-input clearable v-model="ruleForm.oldPassword" placeholder="请输入旧密码"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input clearable v-model="ruleForm.newPassword" show-password type='password'
                  placeholder="请输入新密码"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="qrPassword">
        <el-input clearable v-model="ruleForm.qrPassword" show-password type='password' placeholder="请确认密码"></el-input>
      </el-form-item>
      <el-form-item label="新支付密码" prop="newPayPassword">
        <el-input clearable v-model="ruleForm.newPayPassword" show-password placeholder="请输入新支付密码"></el-input>
      </el-form-item>
      <!--			<el-form-item label="确认支付密码" prop="qrnewPayPassword">-->
      <!--				<el-input clearable v-model="ruleForm.qrnewPayPassword" placeholder="请确认支付密码"></el-input>-->
      <!--			</el-form-item> -->
      <el-form-item label="谷歌验证码" prop="code">
        <el-input clearable v-model="ruleForm.code" placeholder="请输入谷歌验证码"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="determine">提 交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  mapState
} from 'vuex';
import {
  changePassword
} from "@/api/settlement";

let ac;
export default {
  name: 'windowSet',
  data() {
    return {
      options: [],
      ruleForm: {
        oldPassword: "",
        newPassword: "",
        qrPassword: '',
        newPayPassword: '',
        qrnewPayPassword: '',
        code: ''
      },
      rules: {
        oldPassword: [{
          required: true,
          message: "请输入旧密码",
          trigger: "blur"
        }],
        newPassword: [{
          required: true,
          message: "请输入新密码",
          trigger: "blur"
        }],
        qrPassword: [{
          required: true,
          message: "请确认密码",
          trigger: "blur"
        }],
        // newPayPassword: [{
        //   required: true,
        //   message: "请输入新支付密码",
        //   trigger: "blur"
        // }],
        qrnewPayPassword: [{
          required: true,
          message: "请确认支付密码",
          trigger: "blur"
        }],
        code: [{
          required: true,
          message: "请输入谷歌验证码",
          trigger: "blur"
        }],
      },
    };
  },
  mounted() {
  },

  methods: {
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let data = {
            old_password: this.ruleForm.oldPassword,
            password: this.ruleForm.newPassword,
            password_confirm: this.ruleForm.qrPassword,
            pay_password: this.ruleForm.newPayPassword,
            // qrnewPayPassword: this.ruleForm.qrnewPayPassword,
            google: this.ruleForm.code,
          };
          changePassword(data)
              .then((res) => {
                if (res.code === 1) {
                  this.$message.success('保存成功');
                  this.close()
                } else {
                  this.$message.error(res.msg);
                  return;
                }
              })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      (this.ruleForm = {
        oldPassword: "",
        newPassword: "",
        qrPassword: "",
        code: "",
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  //line-height: 175px;
  text-align: center;
}

/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}

/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}

.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}

.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}

/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>